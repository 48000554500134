.slide-in-up-1s {
  animation-duration: 1s;
  animation-name: slide-in-up;
}
@keyframes slide-in-up {
  from {
    transform: translate(0, 60%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0%);
    opacity: 1;
  }
}

.slide-in-down-1s {
  animation-duration: 1s;
  animation-name: slide-in-down;
}
@keyframes slide-in-down {
  from {
    transform: translate(0, -40%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0%);
    opacity: 1;
  }
}

.fade-in-point2s {
  animation-duration: 0.2s;
  animation-name: fade-in;
}
.fade-in-point5s {
  animation-duration: 0.5s;
  animation-name: fade-in;
}
.fade-in-1s {
  animation-duration: 1s;
  animation-name: fade-in;
}
.fade-in-1point3s {
  animation-duration: 1.3s;
  animation-name: fade-in;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out-point2s {
  animation-duration: 0.2s;
  animation-name: fade-out;
}
.fade-out-point5s {
  animation-duration: 0.5s;
  animation-name: fade-out;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in-point2s-1s {
  animation-duration: 1s;
  animation-name: fade-in-point2s-1s;
}
@keyframes fade-in-point2s-1s {
  from {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.account-demo-scrolling {
  animation-duration: 18s;
  animation-name: account-demo-scrolling;
}

@keyframes account-demo-scrolling {
  from {
    transform: translate(0, 0%);
  }
  5% {
    transform: translate(0, 0%);
  }
  30% {
    transform: translate(0, -17%);
  }
  80% {
    transform: translate(0, -52%);
  }
  90% {
    transform: translate(0, -85%);
  }
  to {
    transform: translate(0, 0%);
  }
}

.slideInRight {
  animation-duration: 1s;
  animation-name: SIL;
}
.slideInLeft {
  animation-duration: 1s;
  animation-name: SIR;
}
.slideInUp {
  animation-duration: 1s;
  animation-name: SIU;
}
.fadeInA {
  animation-duration: 0.5s;
  animation-name: FI;
}
.fadeInB {
  animation-duration: 1s;
  animation-name: FI;
}
.fadeInC {
  animation-duration: 1s;
  animation-name: FI;
}
.fadeInD {
  animation-duration: 1.5s;
  animation-name: FI;
}
.fadeInE {
  animation-duration: 2s;
  animation-name: FI;
}

.fadeInF {
  animation-duration: 2.5s;
  animation-name: FI;
}

.fadeInG {
  animation-duration: 3s;
  animation-name: FI;
}
.bounce {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: Bounce;
}
@keyframes SIL {
  from {
    transform: translate(-60%);
    opacity: 0;
  }
  to {
    transform: translate(0%);
    opacity: 1;
  }
}

@keyframes SIR {
  from {
    transform: translate(60%);
    opacity: 0;
  }
  to {
    transform: translate(0%);
    opacity: 1;
  }
}
@keyframes SIU {
  from {
    transform: translate(0, 60%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0%);
    opacity: 1;
  }
}

@keyframes SOL {
  to {
    transform: translate(60%);
    opacity: 0;
  }
  from {
    transform: translate(0%);
    opacity: 1;
  }
}

@keyframes FI {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.transformCustom {
  transform: translate(-60%);
}

.transformCustomB {
  transform: translate(60%);
}
.rotateCustom {
  transform: rotate(180deg);
}
.transformSlideUp {
  transform: translate(0, -60%);
}
.transformSlideDown {
  transform: translate(0, 60%);
}
